import { SubmitHandler, useFormContext } from 'react-hook-form';
import * as Yup from 'yup';
import styled from 'styled-components';

import { EventType } from '../../../generated/types';
import { Colors } from '../../../styles/colors';

import { toast } from 'react-toastify';

import { Form } from '../../../components/atoms/Form/Form';
import { PrimaryButton, SecondaryButton, WrapperButtons } from '../../atoms/Button/Buttons';
import { H2 } from '../../atoms/Typography/Headings';
import { InfoAlert } from '../../atoms/InfoAlert';
import { TextInput } from '../../atoms/Form/TextInput';
import { SelectInput } from '../../../components/atoms/Form/SelectInput/SelectInput';
import { ChevronRightIcon } from '../../atoms/Icons';
import { Copy } from '../../atoms/Typography';
import { PlacesAutocompleteInput } from '../../atoms/Form/PlacesAutocompleteInput';

export const InterviewJobModal = ({ handleCancel, handleSubmit }: InterviewJobModalProps) => {
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (data.type === '' && (data.startAt !== '' || data.endAt !== '')) {
      toast.error('Please select the type of the interview, face to face, phone call, video call or other');
      return;
    }
    handleSubmit(data);
  };

  return (
    <Wrapper>
      <H2>Interview</H2>
      <InfoAlert>
        <Copy marginBottom={16}>
          Would you like to add the interview event related to this job opportunity to your calendar?
        </Copy>
        <Copy styleLevel={2}>
          We can automatically add the interview related to this job opportunity to your calendar. Alternatively, you
          have the option to add the interview information manually later and simply update the status of this job
          opportunity accordingly. The choice is yours.
        </Copy>
      </InfoAlert>
      <Form onSubmit={onSubmit} validationSchema={validationSchema}>
        <SelectInput name="type" noMargin={true} defaultValue="" label="Type">
          <option value="">Select</option>
          <option value={EventType.Face2Face}>Face to face</option>
          <option value={EventType.PhoneCall}>Phone call</option>
          <option value={EventType.VideoCall}>Video call</option>
          <option value={EventType.Other}>Other</option>
        </SelectInput>
        <TextInput name="description" fullWidth type="text" label="Description" defaultValue="" noMargin={true} />
        <TextInput
          name="startAt"
          fullWidth
          type="datetime-local"
          label="Start"
          defaultValue=""
          InputLabelProps={{ shrink: true }}
          noMargin={true}
        />
        <TextInput
          name="endAt"
          fullWidth
          type="datetime-local"
          label="End"
          defaultValue=""
          InputLabelProps={{ shrink: true }}
        />
        <TextInput name="meetingUrl" fullWidth type="text" label="Meeting URL" defaultValue="" noMargin={true} />
        <PlacesAutocompleteInput defaultValue={''} name="location" label="Location" placeholder="Where is the event?" />
        <WrapperButtons>
          <SecondaryButton inline onClick={handleCancel}>
            Cancel
          </SecondaryButton>
          <PrimaryButton type="submit" inline iconRight={<ChevronRightIcon color={Colors.White} />}>
            Interviewing
          </PrimaryButton>
        </WrapperButtons>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
`;

const validationSchema = Yup.object({
  description: Yup.string().max(50, 'Too Long!'),

  type: Yup.string(),

  startAt: Yup.string().when('type', {
    is: (type: any) => {
      return type !== '';
    },
    then: () => Yup.string().required('The start date/time is required'),
  }),

  endAt: Yup.string().when('type', {
    is: (type: any) => {
      return type !== '';
    },
    then: () => Yup.string().required('The end date/time is required'),
  }),
});

type FormData = Yup.InferType<typeof validationSchema>;

interface InterviewJobModalProps {
  handleSubmit: (a: any) => void;
  handleCancel: (a: any) => void;
}
